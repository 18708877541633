<cft-content-section
  [sectionId]="industrySection.id"
  [plot]="industrySection.plot"
  [title]="industrySection.title"
  titleAlignment="left"
>
  <div class="hidden gap-md sm:grid md:grid-cols-2 xl:grid-cols-3">
    @for (item of industrySection.items; track item) {
      <cft-horizontal-list-card
        [attr.id]="identifier('sm', item)"
        [title]="item.title"
        [icon]="item.icon"
        [routerLink]="item.targetPath"
      ></cft-horizontal-list-card>
    }
  </div>

  <div class="block w-full sm:hidden" #container *transloco="let t">
    <cft-slider [alignCenter]="false" [backButtonTitle]="t('slider.back')" [forwardButtonTitle]="t('slider.forward')">
      @for (col of columns; track col) {
        <div class="space-y-md" [style.width.px]="container.offsetWidth || 0 - 16">
          @for (item of col; track item) {
            <cft-horizontal-list-card
              class="block"
              [attr.id]="identifier('xs', item)"
              [title]="item.title"
              [icon]="item.icon"
              [routerLink]="item.targetPath"
            ></cft-horizontal-list-card>
          }
        </div>
      }
    </cft-slider>
  </div>
</cft-content-section>
