import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core'
import {IndustryItem, IndustrySection} from '../../../../core/content/types/industry-section.types'
import {toTwoDimensionalArray} from '../../../../core/utils/array-utils'
import {CardsModule} from '../../../../craft/cards/cards.module'
import {RouterLink} from '@angular/router'
import {SliderModule} from '../../../../craft/slider/slider.module'
import {ContentSectionComponent} from '../../../../craft/content-section/content-section.component'
import {TranslocoModule} from '@ngneat/transloco'

@Component({
  selector: 'bgo-industry-section',
  templateUrl: './industry-section.component.html',
  styles: [':host { display: block }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ContentSectionComponent, CardsModule, RouterLink, SliderModule, TranslocoModule],
  standalone: true,
})
export class IndustrySectionComponent implements OnInit {
  @Input() industrySection!: IndustrySection

  columns!: IndustryItem[][]

  ngOnInit(): void {
    this.columns = toTwoDimensionalArray(this.industrySection.items, 3)
  }

  identifier(prefix: string, item: IndustryItem): string | undefined {
    return item.targetPath ? `industry-item-${prefix}-${item.targetPath.split('/').pop()}` : undefined
  }
}
